export default [
  { value: 'AL', label: 'forms.state.us.alabama' },
  { value: 'AK', label: 'forms.state.us.alaska' },
  { value: 'AZ', label: 'forms.state.us.arizona' },
  { value: 'AR', label: 'forms.state.us.arkansas' },
  { value: 'CA', label: 'forms.state.us.california' },
  { value: 'CO', label: 'forms.state.us.colorado' },
  { value: 'CT', label: 'forms.state.us.connecticut' },
  { value: 'DC', label: 'forms.state.us.dc' },
  { value: 'DE', label: 'forms.state.us.delaware' },
  { value: 'FL', label: 'forms.state.us.florida' },
  { value: 'GA', label: 'forms.state.us.georgia' },
  { value: 'HI', label: 'forms.state.us.hawaii' },
  { value: 'ID', label: 'forms.state.us.idaho' },
  { value: 'IL', label: 'forms.state.us.illinois' },
  { value: 'IN', label: 'forms.state.us.indiana' },
  { value: 'IA', label: 'forms.state.us.iowa' },
  { value: 'KS', label: 'forms.state.us.kansas' },
  { value: 'KY', label: 'forms.state.us.kentucky' },
  { value: 'LA', label: 'forms.state.us.louisiana' },
  { value: 'ME', label: 'forms.state.us.maine' },
  { value: 'MD', label: 'forms.state.us.maryland' },
  { value: 'MA', label: 'forms.state.us.massachusetts' },
  { value: 'MI', label: 'forms.state.us.michigan' },
  { value: 'MN', label: 'forms.state.us.minnesota' },
  { value: 'MS', label: 'forms.state.us.mississippi' },
  { value: 'MO', label: 'forms.state.us.missouri' },
  { value: 'MT', label: 'forms.state.us.montana' },
  { value: 'NE', label: 'forms.state.us.nebraska' },
  { value: 'NV', label: 'forms.state.us.nevada' },
  { value: 'NH', label: 'forms.state.us.newhampshire' },
  { value: 'NJ', label: 'forms.state.us.newjersey' },
  { value: 'NM', label: 'forms.state.us.newmexico' },
  { value: 'NY', label: 'forms.state.us.newyork' },
  { value: 'NC', label: 'forms.state.us.northcarolina' },
  { value: 'ND', label: 'forms.state.us.northdakota' },
  { value: 'OH', label: 'forms.state.us.ohio' },
  { value: 'OK', label: 'forms.state.us.oklahoma' },
  { value: 'OR', label: 'forms.state.us.oregon' },
  { value: 'PA', label: 'forms.state.us.pennsylvania' },
  { value: 'RI', label: 'forms.state.us.rhodeisland' },
  { value: 'SC', label: 'forms.state.us.southcarolina' },
  { value: 'SD', label: 'forms.state.us.southdakota' },
  { value: 'TN', label: 'forms.state.us.tennessee' },
  { value: 'TX', label: 'forms.state.us.texas' },
  { value: 'UT', label: 'forms.state.us.utah' },
  { value: 'VT', label: 'forms.state.us.vermont' },
  { value: 'VA', label: 'forms.state.us.virginia' },
  { value: 'WA', label: 'forms.state.us.washington' },
  { value: 'WV', label: 'forms.state.us.westvirginia' },
  { value: 'WI', label: 'forms.state.us.wisconsin' },
  { value: 'WY', label: 'forms.state.us.wyoming' },
  { value: 'AS', label: 'forms.state.us.americansamoa' },
  { value: 'GU', label: 'forms.state.us.guam' },
  { value: 'PR', label: 'forms.state.us.puertorico' },
  { value: 'VI', label: 'forms.state.us.virginislands' }
]
