/* eslint-disable @typescript-eslint/no-extra-semi */
import usStates from './us_states'
import caStates from './ca_states'

function extend<T, K>(target: T, ...args: K[]) {
  let source

  for (let i = 0; i < args.length; i++) {
    source = args[i]

    for (const prop in source) {
      // recurse for non-API objects
      if (source[prop] && typeof source[prop] === 'object' && !(source instanceof Array)) {
        // eslint-disable-next-line max-depth
        if (!(target as any)[prop] && source[prop]) {
          ;(target as any)[prop] =
            typeof source[prop] === 'object' && !Array.isArray(source[prop]) ? { ...source[prop] } : source[prop]
        } else {
          ;(target as any)[prop] = extend((target as any)[prop], { ...source[prop] })
        }
      } else {
        ;(target as any)[prop] = source[prop]
      }
    }
  }

  return target as T & K
}

function getValueByPath<T>(object: any, path: string, defaults: T) {
  const parts = String(path).split('.')

  while (parts.length) {
    const part = parts.shift()
    if (part && typeof object === 'object' && object !== null && part in object) {
      object = object[part]
    } else if (part && typeof object === 'string') {
      object = object[Number(part)]
      break
    } else {
      object = defaults
      break
    }
  }
  return object
}

function memoize<R, T extends (...args: any[]) => R>(f: T): T {
  const cache: { [key: string]: R } = {}
  function memoizeInner(this: any, ...args: any) {
    const address = args.join('-+-')
    if (!cache[address]) {
      cache[address] = f.apply(this, args)
    }
    return cache[address]
  }
  return memoizeInner as T
}

function formRecaptchaConfig(enable = true) {
  return {
    reCaptcha: {
      extends: 'fields.input.hidden',
      maxlength: 1000,
      enable
    }
  }
}

const fields = {
  input: {
    base: {
      customMessages: {
        required: 'forms.errors.required.field',
        min: 'forms.errors.minlength',
        max: 'forms.errors.maxlength',
        regex: 'forms.errors.parse'
      },
      pattern: '^[^<>{}[\\]!$%*+:=?@^~_]+$'
    },

    text: {
      extends: 'fields.input.base',
      type: 'text',
      minlength: 2,
      maxlength: 256
    },

    textarea: {
      extends: 'fields.input.base',
      type: 'textarea',
      minlength: 1,
      maxlength: 256
    },

    password: {
      extends: 'fields.input.base',
      caption: '',
      label: 'forms.profile.password',
      pattern: '',
      validation: {
        enablePasswordStrength: false
      },
      type: 'password',
      minlength: 1,
      maxlength: 64,
      required: true
    },

    checkbox: {
      type: 'checkbox'
    },

    tel: {
      extends: 'fields.input.base',
      pattern: '^\\+?[0-9-\\s]{7,20}$',
      customMessages: {
        regex: 'forms.errors.parse'
      },
      label: 'forms.generic.phone.label',
      type: 'tel',
      minlength: 7,
      maxlength: 20,
      autocomplete: 'tel',
      l10n: {
        us: {
          caption: 'forms.generic.phone.caption.us',
          format: '1-###-###-####',
          minlength: 14
        },
        ca: {
          caption: 'forms.generic.phone.caption.ca',
          format: '1-###-###-####',
          minlength: 14
        },
        gb: {
          caption: 'forms.generic.phone.caption.gb',
          format: '+## ## #### ####',
          minlength: 16
        },
        ae: {
          caption: 'forms.generic.phone.caption.ае',
          format: '+## ### #######',
          minlength: 15
        }
      }
    },
    hidden: {
      type: 'hidden',
      showLabel: false,
      label: ''
    }
  },
  select: {
    type: 'select',
    prefBased: {
      prefName: 'select.singleoption.static',
      configs: {
        true: {
          widget: {
            attributes: {
              'data-static-option-enabled': 'true'
            }
          }
        },
        false: {
          widget: {
            attributes: {
              'data-static-option-enabled': 'false'
            }
          }
        }
      }
    }
  },
  generic: {
    firstName: {
      extends: 'fields.input.text',
      label: 'forms.generic.firstName',
      maxlength: 50,
      required: true,
      autocomplete: 'given-name',
      pattern: '^(?!\\s*$)[a-zA-Z\u00C0-\u00ff\\d\\s\\-\\.,`()\\/#&;\\x22]+$'
    },
    lastName: {
      extends: 'fields.input.text',
      label: 'forms.generic.lastName',
      maxlength: 50,
      required: true,
      autocomplete: 'family-name',
      pattern: '^(?!\\s*$)[a-zA-Z\u00C0-\u00ff\\d\\s\\-\\.,`()\\/#&;\\x22]+$'
    },
    address1: {
      extends: 'fields.input.text',
      label: 'forms.generic.line1',
      maxlength: 50,
      required: true,
      autocomplete: 'address-line1',
      pattern: '^(?!\\s*$)[a-zA-Z\u00C0-\u00ff\\d\\s\\-\\.,`()\\/#&;\\x22]+$'
    },
    address2: {
      extends: 'fields.input.text',
      label: 'forms.generic.line2',
      minlength: 2,
      maxlength: 50,
      autocomplete: 'address-line2',
      pattern: '^(?!\\s*$)[a-zA-Z\u00C0-\u00ff\\d\\s\\-\\.,`()\\/#&;\\x22]+$'
    },
    city: {
      extends: 'fields.input.text',
      label: 'forms.generic.city',
      minlength: 2,
      maxlength: 50,
      required: true,
      autocomplete: 'address-level2',
      pattern: '^(?!\\s*$)[a-zA-Z\u00C0-\u00ff\\d\\s\\-\\.,`()\\/#&;\\x22]+$'
    },
    postalCode: {
      extends: 'fields.input.text',
      caption: 'forms.generic.zip.caption',
      label: 'forms.generic.zip.code',
      pattern: '^[0-9]{5,10}$',
      customMessages: {
        regex: 'forms.errors.postalcode'
      },
      minlength: 5,
      maxlength: 10,
      required: true,
      autocomplete: 'postal-code',
      l10n: {
        us: {
          extends: 'fields.input.text',
          caption: 'forms.generic.zip.caption.us',
          label: 'forms.generic.zip.code.us',
          pattern: '^\\d{5}([ -]\\d{4})?$'
        },
        ca: {
          extends: 'fields.input.text',
          caption: 'forms.generic.zip.caption.ca',
          label: 'forms.generic.zip.code.ca',
          pattern: '^[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d$'
        },
        gb: {
          extends: 'fields.input.text',
          caption: 'forms.generic.zip.caption.gb',
          label: 'forms.generic.zip.code.gb',
          pattern: '^([A-Z]{1,2}\\d{1,2}[A-Z]?)\\s*(\\d[A-Z]{2})$'
        },
        ae: {
          required: false
        }
      }
    },
    country: {
      type: 'select',
      label: 'forms.address.country',
      autocomplete: 'country-name',
      required: true,
      validateOptions: true,
      l10n: {
        us: {
          extends: 'fields.select',
          options: [
            {
              label: 'forms.country.us',
              value: 'US'
            },
            {
              label: 'forms.country.ca',
              value: 'CA'
            }
          ]
        },
        ca: {
          extends: 'fields.select',
          options: [
            {
              label: 'forms.country.us',
              value: 'US'
            },
            {
              label: 'forms.country.ca',
              value: 'CA'
            }
          ]
        },
        gb: {
          extends: 'fields.select',
          options: [
            {
              label: 'forms.country.gb',
              value: 'GB'
            }
          ]
        }
      },
      customMessages: {
        options: 'forms.errors.outOfRange'
      }
    },
    state: {
      extends: 'fields.input.text',
      label: 'forms.address.state',
      autocomplete: 'address-level1',
      required: true,
      l10n: {
        us: {
          extends: 'fields.select',
          label: 'forms.address.state.us',
          required: true,
          validateOptions: true,
          customMessages: {
            options: 'forms.errors.outOfRange'
          },
          options: usStates,
          placeholder: 'forms.address.pleaseSelect'
        },
        ca: {
          extends: 'fields.select',
          label: 'forms.address.state.ca',
          required: true,
          validateOptions: true,
          options: caStates,
          customMessages: {
            options: 'forms.errors.outOfRange'
          },
          placeholder: 'forms.address.pleaseSelect'
        },
        gb: {
          extends: 'fields.input.text',
          label: 'forms.address.state.gb',
          required: false,
          pattern: '^(?!\\s*$)[a-zA-Z\u00C0-\u00ff\\d\\s\\-\\.,`()\\/#&;\\x22]+$'
        },
        ae: {
          extends: 'fields.input.text',
          label: 'forms.address.state.ae',
          required: true,
          pattern: '^(?!\\s*$)[a-zA-Z\u00C0-\u00ff\\d\\s\\-\\.,`()\\/#&;\\x22]+$'
        }
      }
    },
    phone: {
      extends: 'fields.input.tel',
      required: true
    },
    setAsDefault: {
      extends: 'fields.input.checkbox',
      label: 'forms.address.setDefault'
    },
    saveAddress: {
      extends: 'fields.input.checkbox',
      label: 'forms.address.saveaddress'
    },
    useShipping: {
      extends: 'fields.input.checkbox',
      label: 'forms.address.useshipping'
    },
    useBilling: {
      extends: 'fields.input.checkbox',
      label: 'forms.address.usebilling'
    },
    cardType: {
      extends: 'fields.input.text',
      label: 'forms.payment.card.type',
      type: 'hidden',
      maxlength: 50,
      required: true
    },
    cardOwner: {
      extends: 'fields.input.text',
      label: 'forms.payment.card.owner',
      maxlength: 50,
      required: true,
      autocomplete: 'cc-name'
    },
    cardNumber: {
      extends: 'fields.input.text',
      label: 'forms.payment.card.number',
      caption: 'forms.payment.card.number.caption',
      pattern: '^(\\d\\s?){13,19}$',
      required: true,
      autocomplete: 'cc-number'
    },
    expirationMonth: {
      extends: 'fields.select',
      label: 'forms.payment.card.month',
      showLabel: false,
      required: true,
      autocomplete: 'cc-exp-month'
    },
    expirationYear: {
      extends: 'fields.select',
      label: 'forms.payment.card.year',
      showLabel: false,
      required: true,
      emptyoption: 'forms.payment.card.year.emptyoption',
      'options.script': '*/cartridge/config/exp_years',
      autocomplete: 'cc-exp-year'
    },
    securityCode: {
      extends: 'fields.input.text',
      label: 'forms.payment.card.cvv',
      caption: 'forms.payment.card.cvv.caption',
      pattern: '^[0-9]+$',
      type: 'text',
      maxlength: 4,
      minlength: 3,
      required: true,
      autocomplete: 'cc-csc'
    },
    email: {
      extends: 'fields.input.base',
      type: 'email',
      autocomplete: 'email',
      maxlength: 63,
      required: true,
      pattern:
        // eslint-disable-next-line quotes, max-len
        "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
      customMessages: {
        regex: 'forms.errors.email.valid'
      },
      label: 'forms.profile.email'
    },
    agreeToPrivacy: {
      extends: 'fields.input.checkbox',
      customMessages: {
        required: 'forms.errors.required.agreements'
      },
      required: true
    },
    password: {
      extends: 'fields.input.password'
    },
    complexPassword: {
      extends: 'fields.input.password',
      customMessages: {
        min: 'forms.errors.password.length',
        upperCaseAmount: 'forms.errors.password.upperCase',
        lowerCaseAmount: 'forms.errors.password.lowerCase',
        numbersAmount: 'forms.errors.password.number',
        specialCharsAmount: 'forms.errors.password.special'
      },
      pattern:
        // eslint-disable-next-line quotes, max-len
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])',

      enablePasswordStrength: true,
      minlength: 8
    },
    orderNumber: {
      extends: 'fields.input.base',
      label: 'forms.trackorder.number.label',
      caption: 'forms.trackorder.number.caption',
      required: true,
      type: 'text',
      minlength: 1,
      maxlength: 24,
      pattern: '^[\\w-]+$',
      customMessages: {
        regex: 'validation.errors.ordernumber'
      }
    },
    googleRecaptcha: {
      extends: 'fields.input.hidden',
      error: {
        show: true
      }
    },
    searchByPlace: {
      extends: 'fields.input.text',
      label: 'forms.storelocator.searchByPlace',
      required: true,
      maxlength: 50,
      autocomplete: 'off',
      pattern: '^(?!\\s*$)[a-zA-Z\u00C0-\u00ff\\d\\s\\-\\.,`()\\/#&;\\x22]+$',
      customMessages: {
        regex: 'validation.errors.value'
      },
      l10n: {
        us: {
          caption: 'forms.storelocator.searchByPlace.caption'
        },
        ca: {
          caption: 'forms.storelocator.searchByPlace.caption.ca'
        },
        gb: {
          caption: 'forms.storelocator.searchByPlace.caption.gb'
        }
      }
    }
  }
}

const dynamicForms = {
  fields,
  forms: {
    registration: {
      firstName: {
        extends: 'fields.generic.firstName'
      },
      lastName: {
        extends: 'fields.generic.lastName'
      },
      phone: {
        extends: 'fields.generic.phone'
      },
      email: {
        extends: 'fields.generic.email'
      },
      emailconfirm: {
        extends: 'fields.generic.email',
        label: 'forms.profile.emailConfirm',
        validationRules: {
          'email-confirm': '@email'
        },
        customMessages: {
          'email-confirm': 'forms.errors.email.confirm'
        }
      },
      password: {
        extends: 'fields.generic.complexPassword',
        autocomplete: 'new-password'
      },
      passwordconfirm: {
        extends: 'fields.generic.complexPassword',
        label: 'forms.profile.passwordconfirm',
        autocomplete: 'new-password',
        validationRules: {
          'password-confirm': '@password'
        },
        customMessages: {
          'password-confirm': 'forms.errors.password.confirm'
        }
      },
      agreeToPrivacy: {
        extends: 'fields.generic.agreeToPrivacy'
      },
      ...formRecaptchaConfig()
    },
    loginProfile: {
      currentpassword: {
        extends: 'fields.generic.password',
        caption: 'forms.profile.currentpassword.caption',
        label: 'forms.profile.currentpassword',
        autocomplete: 'current-password'
      },
      password: {
        extends: 'fields.generic.complexPassword',
        autocomplete: 'new-password'
      },
      passwordconfirm: {
        extends: 'fields.generic.complexPassword',
        label: 'forms.profile.passwordconfirm',
        autocomplete: 'new-password',
        validationRules: {
          'password-confirm': '@password'
        },
        customMessages: {
          'password-confirm': 'forms.errors.password.confirm'
        }
      }
    },
    resetPasswordProfile: {
      email: {
        extends: 'fields.generic.email'
      },
      ...formRecaptchaConfig()
    },
    resetPasswordMigratedProfile: {
      email: {
        extends: 'fields.generic.email',
        type: 'hidden'
      }
    },
    address: {
      firstName: {
        extends: 'fields.generic.firstName'
      },
      lastName: {
        extends: 'fields.generic.lastName'
      },
      phone: {
        extends: 'fields.generic.phone'
      },
      country: {
        extends: 'fields.generic.country'
      },
      address1: {
        extends: 'fields.generic.address1'
      },
      address2: {
        extends: 'fields.generic.address2'
      },
      state: {
        extends: 'fields.generic.state'
      },
      city: {
        extends: 'fields.generic.city',
        widget: {
          classes: 'm-small'
        }
      },
      postalCode: {
        extends: 'fields.generic.postalCode'
      },
      setDefaultShipping: {
        extends: 'fields.generic.setAsDefault',
        label: 'forms.address.setDefaultShipping'
      },
      setDefaultBilling: {
        extends: 'fields.generic.setAsDefault',
        label: 'forms.address.setDefaultBilling'
      },
      ...formRecaptchaConfig()
    },
    shippingAddress: {
      firstName: {
        extends: 'fields.generic.firstName'
      },
      lastName: {
        extends: 'fields.generic.lastName'
      },
      phone: {
        extends: 'fields.generic.phone',
        l10n: {
          us: {
            caption: 'forms.checkoutAddress.phone.caption.us'
          },
          ca: {
            caption: 'forms.checkoutAddress.phone.caption.ca'
          },
          gb: {
            caption: 'forms.checkoutAddress.phone.caption.gb'
          },
          ae: {
            caption: 'forms.checkoutAddress.phone.caption.us'
          }
        }
      },
      address1: {
        extends: 'fields.generic.address1'
      },
      address2: {
        extends: 'fields.generic.address2'
      },
      country: {
        extends: 'fields.generic.country'
      },
      state: {
        extends: 'fields.generic.state'
      },
      city: {
        extends: 'fields.generic.city'
      },
      postalCode: {
        extends: 'fields.generic.postalCode'
      }
    },
    billingAddress: {
      firstName: {
        extends: 'fields.generic.firstName'
      },
      lastName: {
        extends: 'fields.generic.lastName'
      },
      phone: {
        extends: 'fields.generic.phone',
        caption: 'forms.shipment.checkout.phone.caption',
        l10n: {
          us: {
            caption: 'forms.checkoutAddress.phone.caption.us'
          },
          ca: {
            caption: 'forms.checkoutAddress.phone.caption.ca'
          },
          gb: {
            caption: 'forms.checkoutAddress.phone.caption.gb'
          },
          ae: {
            caption: 'forms.checkoutAddress.phone.caption.us'
          }
        }
      },
      address1: {
        extends: 'fields.generic.address1'
      },
      address2: {
        extends: 'fields.generic.address2'
      },
      country: {
        extends: 'fields.generic.country'
      },
      state: {
        extends: 'fields.generic.state'
      },
      city: {
        extends: 'fields.generic.city'
      },
      postalCode: {
        extends: 'fields.generic.postalCode'
      }
    },
    billingContactInfoFields: {
      email: {
        extends: 'fields.generic.email',
        caption: 'checkout.billing.emailMsg'
      }
    },
    billingCreditCardFields: {
      cardType: {
        extends: 'fields.generic.cardType'
      },
      cardOwner: {
        extends: 'fields.generic.cardOwner'
      },
      cardNumber: {
        extends: 'fields.generic.cardNumber'
      },
      expirationMonth: {
        extends: 'fields.generic.expirationMonth'
      },
      expirationYear: {
        extends: 'fields.generic.expirationYear'
      },
      securityCode: {
        extends: 'fields.generic.securityCode'
      },
      savedCardSecurityCode: {
        extends: 'fields.generic.securityCode'
      },
      saveCard: {
        extends: 'fields.input.checkbox',
        checked: true,
        label: 'forms.payment.checkbox.saveForNextPayment'
      }
    },
    emailsubscribe: {
      email: {
        extends: 'fields.generic.email',
        showLabel: false,
        label: 'forms.emailsubscribe.email',
        required: true,
        classes: 'b-newsletters-input',
        placeholder: 'forms.emailsubscribe.email.placeholder',
        skipValidationOnBlur: true
      },
      agreeToPrivacy: {
        extends: 'fields.generic.agreeToPrivacy',
        label: 'forms.emailsubscribe.agreeToPrivacy',
        customMessages: {
          required: 'forms.emailsubscribe.agreeToPrivacy.required'
        },
        required: true
      }
    },
    login: {
      email: {
        extends: 'fields.generic.email',
        label: 'forms.profile.email'
      },
      password: {
        extends: 'fields.generic.password',
        label: 'forms.profile.password',
        autocomplete: 'current-password',
        caption: ''
      },
      rememberMe: {
        extends: 'fields.input.checkbox',
        label: 'forms.profile.rememberMe'
      },
      ...formRecaptchaConfig()
    },
    changePassword: {
      currentpassword: {
        extends: 'fields.generic.password',
        caption: 'forms.changePassword.currentpassword.caption',
        label: 'forms.changePassword.currentpassword.label',
        autocomplete: 'current-password',
        mask: true
      },
      newpassword: {
        extends: 'fields.generic.complexPassword',
        enablePasswordStrength: true,
        label: 'forms.changePassword.newpassword',
        autocomplete: 'new-password',
        mask: true
      },
      newpasswordconfirm: {
        extends: 'fields.generic.complexPassword',
        label: 'forms.profile.passwordconfirm',
        autocomplete: 'new-password',
        mask: true,
        validationRules: {
          'password-confirm': '@newpassword'
        },
        customMessages: {
          'password-confirm': 'forms.errors.password.confirm'
        }
      },
      ...formRecaptchaConfig()
    },
    coupon: {
      couponCode: {
        extends: 'fields.input.text',
        showLabel: false,
        required: true,
        caption: 'forms.couponCode.caption',
        customMessages: {
          required: 'forms.couponCode.required'
        },
        placeholder: 'forms.couponCode.placeholder',
        skipValidationOnBlur: true,
        autocapitalize: 'off',
        autocomplete: 'off',
        autocorrect: 'false',
        spellcheck: 'false',
        label: 'promoCode.accordionLabel'
      }
    },
    creditCard: {
      cardType: {
        extends: 'fields.generic.cardType',
        widget: {
          attributes: {
            'data-id': 'cardType'
          }
        },
        type: 'hidden',
        required: true
      },
      cardOwner: {
        extends: 'fields.generic.cardOwner',
        label: 'forms.payment.card.owner.alt'
      },
      cardNumber: {
        extends: 'fields.generic.cardNumber'
      },
      expirationMonth: {
        extends: 'fields.generic.expirationMonth'
      },
      expirationYear: {
        extends: 'fields.generic.expirationYear'
      },
      securityCode: {
        extends: 'fields.generic.securityCode'
      },
      makeDefaultPayment: {
        extends: 'fields.generic.setAsDefault',
        label: 'forms.payment.checkbox.makedefault'
      },
      googleRecaptcha: {
        extends: 'fields.generic.googleRecaptcha'
      }
    },
    trackOrder: {
      orderId: {
        extends: 'fields.generic.orderNumber'
      },
      email: {
        extends: 'fields.generic.email',
        showLabel: true,
        label: 'forms.profile.email'
      },
      ...formRecaptchaConfig()
    },
    contactUs: {
      name: {
        extends: 'fields.generic.firstName',
        label: 'forms.contact.name'
      },
      email: {
        extends: 'fields.generic.email',
        label: 'forms.contact.email'
      },
      phone: {
        extends: 'fields.generic.phone',
        required: false
      },
      orderNumber: {
        extends: 'fields.generic.orderNumber',
        label: 'forms.contact.orderNo',
        caption: '',
        required: false
      },
      questionType: {
        extends: 'fields.select',
        showLabel: true,
        label: 'forms.contact.questionType',
        required: true
      },
      message: {
        extends: 'fields.input.textarea',
        label: 'forms.contact.message',
        required: true,
        maxcounter: true,
        maxlength: 5000,
        placeholder: 'forms.contact.message.placeholder'
      }
    },
    newPasswords: {
      newpassword: {
        extends: 'fields.generic.complexPassword',
        enablePasswordStrength: true,
        autocomplete: 'new-password'
      },
      newpasswordconfirm: {
        extends: 'fields.generic.complexPassword',
        label: 'forms.profile.passwordconfirm',
        autocomplete: 'new-password',
        validationRules: {
          'password-confirm': '@newpassword'
        },
        customMessages: {
          'password-confirm': 'forms.errors.password.confirm'
        }
      },
      agreeToPrivacy: {
        extends: 'fields.generic.agreeToPrivacy'
      }
    },
    setNewPassword: {
      newpassword: {
        extends: 'fields.generic.complexPassword',
        enablePasswordStrength: true,
        autocomplete: 'new-password',
        caption: 'forms.profile.passwordcaption'
      },
      newpasswordconfirm: {
        extends: 'fields.generic.complexPassword',
        label: 'forms.profile.passwordconfirm',
        autocomplete: 'new-password',
        validationRules: {
          'password-confirm': '@newpassword'
        },
        customMessages: {
          'password-confirm': 'forms.errors.password.confirm'
        }
      },
      token: {
        extends: 'fields.input.hidden'
      },
      email: {
        extends: 'fields.input.hidden'
      }
    },
    backInStock: {
      email: {
        extends: 'fields.generic.email',
        dynamicName: true
      },
      productID: {
        extends: 'fields.input.text',
        dynamicName: true
      },
      agreeToCookiePolicies: {
        extends: 'fields.generic.agreeToPrivacy',
        label: 'forms.emailsubscribe.agreeToPrivacy',
        dynamicName: true
      }
    },
    productOption: {
      customOptionText: {
        customMessages: {
          required: 'validation.errors.required.customOptionField.text'
        },
        extends: 'fields.input.textarea',
        showLabel: false,
        label: 'forms.customProductOption',
        classes: 'm-no_resize',
        maxcounter: true,
        rows: 3,
        maxlength: 20,
        placeholder: 'forms.customProductOption.placeholder'
      }
    },
    storeLocatorSearch: {
      searchByPlace: {
        extends: 'fields.generic.searchByPlace'
      }
    },
    countrySelector: {
      country: {
        extends: 'fields.select',
        caption: 'forms.countrySelector.country.caption',
        label: 'forms.countrySelector.country.label',
        required: false
      },
      currency: {
        extends: 'fields.select',
        label: 'forms.countrySelector.currency.label',
        required: false
      }
    },
    profileDetails: {
      firstName: {
        extends: 'fields.generic.firstName'
      },
      lastName: {
        extends: 'fields.generic.lastName'
      },
      phone: {
        extends: 'fields.generic.phone'
      },
      currentpassword: {
        extends: 'fields.generic.password',
        customMessages: {
          min: 'forms.errors.password.valid',
          upperCaseAmount: 'forms.errors.password.valid',
          lowerCaseAmount: 'forms.errors.password.valid',
          numbersAmount: 'forms.errors.password.valid',
          specialCharsAmount: 'forms.errors.password.valid'
        },
        label: 'forms.profile.currentpassword',
        autocomplete: 'current-password',
        caption: 'forms.profile.caption'
      },
      ...formRecaptchaConfig()
    }
  }
}

function extendAndLocalize(field: any, countryCode: string) {
  if (field.extends) {
    const next = getValueByPath(dynamicForms, field.extends, {})
    if (next.extends) {
      const fieldNext = extendAndLocalize(next, countryCode)
      field = extend({}, fieldNext, field)
    } else {
      field = extend({}, next, field)
    }
  }

  if (field.l10n && field.l10n[countryCode]) {
    const nextL10n = field.l10n[countryCode]
    const fieldNextL10n = extendAndLocalize(nextL10n, countryCode)
    field = extend({}, field, fieldNextL10n)
  }

  return field
}

export interface IDynamicField {
  type: string
  attributes?: {
    [key: string]: string
  }
  required: boolean
  placeholder?: string
  show?: boolean
  maxlength?: number
  minlength?: number
  caption?: string
  options?: {
    value: string
    label: string
  }[]
  validateOptions?: boolean
  label: string
  showLabel?: boolean
  enablePasswordStrength?: boolean
  pattern?: string
  customMessages: {
    [key: string]: string
  }
  format?: string
  countryCode?: string
  enable?: boolean
}
export interface IDynamicForm {
  [key: string]: IDynamicField
}

function formByCountry(formName: keyof typeof dynamicForms.forms, countryCode: string) {
  const lowercaseCountryCode = countryCode.toLocaleLowerCase()

  const inititalForm = dynamicForms.forms[formName]

  const localizedForm = Object.keys(inititalForm).reduce((form, fieldKey: string) => {
    const initialField = (inititalForm as any)[fieldKey]

    const field = extendAndLocalize({ ...initialField }, lowercaseCountryCode)

    return { ...form, [fieldKey]: field }
  }, {})

  return localizedForm as IDynamicForm
}

export const getFormByCountry = memoize(formByCountry)

interface FieldError {
  [key: string]: string
}

export async function checkForm(formName: keyof typeof dynamicForms.forms, formData: { [key: string]: string }) {
  const currentFormDefinition = getFormByCountry(formName, formData?.country || 'any')

  // eslint-disable-next-line complexity
  return Object.keys(currentFormDefinition).reduce((fieldError: FieldError, fieldKey) => {
    const fieldDefinition = currentFormDefinition[fieldKey]
    const value = formData[fieldKey]
    const errors: string[] = []

    if (fieldDefinition?.required && !value && fieldDefinition.customMessages?.required) {
      errors.push(fieldDefinition.customMessages?.required)
    }
    if (
      value &&
      typeof fieldDefinition?.minlength === 'number' &&
      value.length < fieldDefinition.minlength &&
      fieldDefinition.customMessages.min
    ) {
      errors.push(fieldDefinition.customMessages.min)
    }
    if (
      value &&
      typeof fieldDefinition?.maxlength === 'number' &&
      value.length > fieldDefinition.maxlength &&
      fieldDefinition.customMessages.max
    ) {
      errors.push(fieldDefinition.customMessages.max)
    }
    if (fieldDefinition?.pattern && value && fieldDefinition.customMessages.regex) {
      const reg = new RegExp(fieldDefinition.pattern)
      if (!reg.test(value)) {
        errors.push(fieldDefinition.customMessages.regex)
      }
    }

    if (
      fieldDefinition &&
      fieldDefinition.type === 'select' &&
      fieldDefinition.validateOptions &&
      ((fieldDefinition.options && !fieldDefinition.options.some((option) => option.value === value)) ||
        (fieldDefinition.required && !fieldDefinition.options))
    ) {
      errors.push(fieldDefinition.customMessages?.required || 'forms.errors.required.field')
    }

    const error = errors.at(0)
    if (typeof error === 'string') {
      // make join/split if you need to show more than first error
      fieldError[fieldKey] = error
    }

    return fieldError
  }, {})
}

export async function verifyForm(formName: keyof typeof dynamicForms.forms, formData: { [key: string]: string }) {
  const fieldErrors = await checkForm(formName, formData)
  if (Object.keys(fieldErrors).length) {
    throw { ok: false, error: { ...fieldErrors } }
  }
}

export const mappingEndpointToFormDefinition = Object.freeze({
  logIn: 'login',
  register: 'registration',
  changePassword: 'changePassword',
  updateCustomer: 'profileDetails',
  addCustomerAddress: 'address',
  updateShippingAddress: 'shippingAddress',
  updateBillingAddress: 'billingAddress',
  updateCustomerEmail: 'billingContactInfoFields',
  changeCustomerAddress: 'address',
  getOrder: 'trackOrder',
  requestResetPasswordToken: 'resetPasswordProfile',
  setNewPassword: 'setNewPassword'
})

export async function verifyIncommingCall({
  callName,
  args
}: {
  callName: keyof typeof mappingEndpointToFormDefinition
  args: any
}) {
  if (mappingEndpointToFormDefinition[callName]) {
    await verifyForm((mappingEndpointToFormDefinition as any)[callName], args[0])
  }
  return args
}
