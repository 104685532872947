export default [
  { value: 'AB', label: 'forms.state.ca.alberta' },
  { value: 'BC', label: 'forms.state.ca.britishcolumbia' },
  { value: 'MB', label: 'forms.state.ca.manitoba' },
  { value: 'NB', label: 'forms.state.ca.newbrunswick' },
  { value: 'NL', label: 'forms.state.ca.newfoundland' },
  { value: 'NS', label: 'forms.state.ca.novascotia' },
  { value: 'ON', label: 'forms.state.ca.ontario' },
  { value: 'PE', label: 'forms.state.ca.princeedwardisland' },
  { value: 'QC', label: 'forms.state.ca.quebec' },
  { value: 'SK', label: 'forms.state.ca.saskatchewan' },
  { value: 'NT', label: 'forms.state.ca.northwestterritories' },
  { value: 'NU', label: 'forms.state.ca.nunavut' },
  { value: 'YT', label: 'forms.state.ca.yukon' }
]
